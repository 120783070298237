body {
  overflow-y: hidden;
}

.app-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 108px !important;
  height: 108px !important;
}

/*Hack Clarity alert*/
.alert-global {
  font-size: 16px;
  font-weight: 400;
  font-family: Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  padding: 12px !important;
  border: none !important;
  margin-top: 0px !important;
  max-height: 96px;
}

.alert-global-align {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.tooltip-content {
  white-space: normal;
  word-wrap: break-word;
}

.alert.alert-warning {
  padding: 10px 12px;
}

.alert-warning .alert-actions button {
  margin: -2px 0 0px 0;
}

.modal-header {
  padding: 0;
}

.rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* set overflow bar style */
::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #ccc;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.custom-h2 {
  line-height: 24px;
}

.datagrid-header {
  z-index: 0 !important;
}

.color-green {
  color: #00d40f;
}

.color-red {
  color: red;
}

.datagrid-table,
.datagrid-header {
  position: inherit !important;
}

.required:after {
  content: "*";
  font-size: 0.58479532rem;
  line-height: 0.5rem;
  color: #c92100;
  margin-left: 0.25rem;
}

.flex-items-xs-right {
  justify-content: flex-end;
}

.clr-vertical-nav .nav-link {
  border-radius: 5px;
}

button:focus {
  outline: none;
}

clr-dropdown div:focus {
  outline: none;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 1rem 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-2 {
  margin: 1.5rem 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-3 {
  margin: 3rem 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 1rem 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-2 {
  padding: 1.5rem 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-3 {
  padding: 3rem 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

.float-lg-right {
  float: right;
}

.position-relative {
  position: relative;
}

clr-datagrid clr-dg-row .datagrid-scrolling-cells {
  align-items: center;
  width: 100%;
}
clr-datagrid clr-dg-row clr-dg-cell {
  overflow-wrap: break-word;
}
clr-datagrid clr-dg-row .datagrid-expandable-caret-icon {
  margin-top: 0;
}
clr-datagrid .datagrid-select {
  display: flex;
  align-items: center;
}
clr-datagrid .datagrid-select clr-checkbox-wrapper .clr-control-label,
clr-datagrid .datagrid-select clr-radio-wrapper .clr-control-label {
  min-height: 0.8rem;
  width: 0.5rem;
}
clr-datagrid .datagrid-table .datagrid-column .datagrid-column-title,
clr-datagrid .datagrid-table .datagrid-column clr-dg-filter {
  align-self: center;
}

button:focus {
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-r {
  text-align: right !important;
}